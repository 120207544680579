import Profil from "./components/Common/Pages/Profil";
import APIKey from "./components/Admin/APIKEY/APIKey";
import UserManagement from "./components/UserManagement/UserManagement";
import CompanyManagement from "./components/CompaniesManagement/CompanyManagement";
import Emplacement from "./components/Emplacement/Emplacement";
import DashboardRepport from "./components/Reports/DashboardReports/DashboardReport";
import WriteReportView from "./components/Reports/EditReports/WriteReportView";
import ComplainForm from "./components/Casserole/Plaintes/ComplainForm";
import AccessRightManagement from "./components/Admin/AccessRight/AccessRightManagement";
import ArticleView from "./components/Articles/DisplayArticles/ArticleView";
import WriteArticleView from "./components/Articles/EditArticles/WriteArticleView";
import SpecialMessageEditor from "./components/Casserole/SpecialMessage/SpecialMessageEditor";
import FranchiseeSurvey from "./components/Survey/FranchiseeSurvey";
import InternalEmployeeSurvey from "./components/Survey/InternalEmployeeSurvey";
import RecipesView from "./components/Recipes/RecipesView";
import BottinTelephonique from "./components/BotinTelephonique/BottinTelephonique";

const AppRoutes = [
  {
    index: true,
    element: <ArticleView />,
    requiredRole: null,
  },
  {
    path: "/Articles",
    element: <ArticleView />,
    requiredRole: null,
  },
  {
    path: "/WriteArticle",
    element: <WriteArticleView />,
    requiredRole: ["AdminTI", "ArticleWriter", "ArticleAdmin"],
  },
  {
    path: "/Profil",
    element: <Profil />,
    requiredRole: null,
  },
  {
    path: "/SpecialMessage",
    element: <SpecialMessageEditor />,
    requiredRole: ["AdminTI", "CasseroleAdmin"],
  },
  {
    path: "/APIKey",
    element: <APIKey />,
    requiredRole: ["AdminTI"],
  },
  {
    path: "/UserManagement",
    element: <UserManagement />,
    requiredRole: ["AdminTI", "Franchisees", "Manager", "InternalEmployee"],
  },
  {
    path: "/CompanyManagement",
    element: <CompanyManagement />,
    requiredRole: ["AdminTI", "CompanyAdmin", "CompanyReader"],
  },
  {
    path: "/Location",
    element: <Emplacement />,
    requiredRole: ["AdminTI", "LocationAdmin", "LocationReader"],
  },
  {
    path: "/Reports",
    element: <WriteReportView />,
    requiredRole: ["AdminTI", "ReportAdmin", "ReportWriter"],
  },
  {
    path: "/Dashboard",
    element: <DashboardRepport />,
    requiredRole: ["AdminTI", "ReportAdmin", "ReportWriter"],
  },
  {
    path: "/CompetitionSurveyFranchisees",
    element: <FranchiseeSurvey />,
    requiredRole: ["AdminTI", "Franchisees"],
  },
  {
    path: "/CompetitionSurveyInternalEmployees",
    element: <InternalEmployeeSurvey />,
    requiredRole: ["AdminTI", "InternalEmployee"],
  },
  {
    path: "/Complaint",
    element: <ComplainForm />,
    requiredRole: ["AdminTI", "CasseroleAdmin"],
  },
  {
    path: "/AccessRight",
    element: <AccessRightManagement />,
    requiredRole: ["AdminTI"],
  },
  {
    path: "/Recipes",
    element: <RecipesView />,
    requiredRole: null,
  },
  {
    path: "/Bottin",
    element: <BottinTelephonique />,
    requiredRole: null,
  },
];

export default AppRoutes;
