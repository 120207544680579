import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

function BottinTelephonique() {
  const [employees, setEmployees] = useState([]);
  const [rows, setRows] = useState([]);
  const userState = useSelector((state) => state.user);
  const { t } = useTranslation(["Bottin"]);

  const columns = [
    { field: "numPoste", headerName: t("numPoste"), width: 80 },
    {
      field: "fullname",
      headerName: t("fullname"),
      width: 180,
    },
    {
      field: "title",
      headerName: t("title"),
      width: 250,
    },
    { field: "email", headerName: t("Email"), width: 250 },
  ];

  useEffect(() => {
    const fetchEmployebureauChef = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/employee/GetEmployeBureauChef`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userState.token}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setEmployees(data);
        } else {
          const errorText = await response.text();
          console.error("Error fetching employees:", errorText);
        }
      } catch (error) {
        console.error("Error during fetch:", error);
      }
    };

    fetchEmployebureauChef();
  }, [userState.token]);

  const Buildrows = () => {
    const rows = employees
      .map((e) => {
        if (e.doitEtreAfficher) {
          return {
            id: e.idBottinTelephoniqueEmployesBureauChef,
            numPoste: e.numPoste,
            fullname: e.fullName,
            title: userState.lang === "EN" ? e.fonctionEng : e.fonctionFr,
            email: e.courriel,
          };
        }
        return null;
      })
      .filter(Boolean);
    setRows(rows);
  };

  useEffect(() => {
    Buildrows();
  }, [employees, userState.lang]);

  return (
    <div>
      <div
        className="container"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "20px",
        }}
      >
        <div
          style={{
            height: "600px",
            width: "100%",
          }}
        >
          <DataGrid
            editMode="row"
            rows={rows}
            columns={columns}
            slots={{
              toolbar: GridToolbar,
            }}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            sx={{
              m: 2,
              borderRadius: 2,
              boxShadow: 2,
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#f5f5f5",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-row:nth-of-type(odd)": {
                backgroundColor: "#f9f9f9",
              },
              "& .MuiDataGrid-row:nth-of-type(even)": {
                backgroundColor: "#ffffff",
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: "#e6f7ff",
              },
              "& .MuiDataGrid-footerContainer": {
                justifyContent: "center",
              },
            }}
          />
        </div>
        <div
          style={{
            backgroundColor: "rgb(255, 188, 15)",
            color: "white",
            borderRadius: "8px",
            padding: "20px",
            marginTop: "20px",
            textAlign: "center",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <h2>{t("ContactUs")}</h2>
          <p>
            <strong>{t("Address")} :</strong> 16 Sicard, local 50,
            Sainte-Thérèse, QC, J7E 3W7
          </p>
          <p>
            <strong>Québec :</strong> T: 450-435-2426 <br /> F: 450-435-2428
          </p>
          <p>
            <strong>{t("OtherProvince")}</strong> T: 905-673-2672 <br /> F:
            905-673-8271
          </p>
          <br />

          <h3>Casseroles</h3>
          <p>
            <strong>Contact :</strong> T: 450-433-2779 <br /> F: 450-433-4768
          </p>
          <br />

          <h3>Dooliz</h3>
          <p>T : 1-844-366-5490</p>
        </div>
      </div>
      <style jsx>{`
        @media (max-width: 768px) {
          .container {
            flex-direction: column;
          }
          div[style] {
            width: 95% !important;
          }
          .MuiDataGrid-root {
            font-size: 0.8rem;
          }
        }
        @media (max-width: 480px) {
          .MuiDataGrid-root {
            font-size: 0.7rem;
          }
        }
      `}</style>
    </div>
  );
}

export default BottinTelephonique;
